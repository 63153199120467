import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const THRESHOLD = [ 0.5 ];

function Resume({ resumeData, currentPageSet }) {
	const [ ref, inView ] = useInView({ threshold: THRESHOLD });

	useEffect(
		() => {
			if (inView) {
				currentPageSet('Resume');
			}
		},
		[ inView, currentPageSet ]
	);

	return (
		<section id="resume" ref={ref}>
			<div className="row education">
				<div className="three columns header-col">
					<h1>
						<span>Education</span>
					</h1>
				</div>

				<div className="nine columns main-col">
					{resumeData.education &&
						resumeData.education.map((item, i) => {
							return (
								<div className="row item" key={i}>
									<div className="twelve columns">
										<h3>{item.UniversityName}</h3>
										<p className="info">
											{item.specialization}
											<span>&bull;</span>{' '}
											<em className="date">
												{item.MonthOfPassing} {item.YearOfPassing}
											</em>
										</p>
										<p>{item.Location}</p>
									</div>
								</div>
							);
						})}
				</div>
			</div>
			{resumeData.work ? (
				<div className="row work">
					<div className="three columns header-col">
						<h1>
							<span>Work</span>
						</h1>
					</div>

					<div className="nine columns main-col">
						{resumeData.work &&
							resumeData.work.map((item, i) => {
								return (
									<div className="row item" key={i}>
										<div className="twelve columns">
											<h3>{item.CompanyName}</h3>
											<p className="info">
												{item.specialization}
												<span>&bull;</span>{' '}
												<em className="date">
													{item.MonthOfLeaving} {item.YearOfLeaving}
												</em>
											</p>
											<p>{item.Achievements}</p>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			) : null}
			<div className="row skill">
				<div className="three columns header-col">
					<h1>
						<span>Skills</span>
					</h1>
				</div>

				<div className="nine columns main-col">
					{resumeData.skillsTypes.map((skill, i) => (
						<div className="skill-type" key={i}>
							<p>{skill.type}</p>
							<div className="bars">
								<ul className="skills">
									{skill.names.map((item, i) => {
										return <li key={i}>{item}</li>;
									})}
								</ul>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="resume-download">
				<a href={resumeData.resumeUrl} target="_blank" rel="noopener noreferrer">
					<button>
						<i className="fa fa-download" /> Detailed Resume
					</button>
				</a>
			</div>
		</section>
	);
}
export default Resume;
